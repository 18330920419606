import React from 'react';
import { withRouter } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import SearchByCategory from './SearchByCategory';
import SearchByGoal from './SearchByGoal';
import BuyerGuide from './BuyerGuide';
import AboutSlideSection from './AboutSlideSection';
import FeedbackSection from './FeedbackSection';
import AreYouVendor from 'src/components/AreYouVendor';
import BePartOfCommunity from './BePartOfCommunity';
import { Helmet } from "react-helmet";

class HomeView extends React.Component {

  constructor(props){
    super(props);
    this.myRef1 = React.createRef();
  }

  handleScroll(location){
    if(location.hash === "#search-by-goal"){
        if(this.myRef1.current){
          return this.myRef1.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
  }
  componentDidMount(){
    this.props.history.listen((location, action) => {
      if(location.hash === '#search-by-goal'){
        setTimeout(() => {
          this.handleScroll(this.props.history.location);
        }, 100);
      }
    });  
    this.handleScroll(this.props.history.location);
    setTimeout(() => {
      if(this.props.history.location.hash === "#search-by-goal"){
        if(this.myRef1.current){
          return this.myRef1.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }, 500);
  }


  render(){
      return (
        <Page
          title="GlobalFleet Marketplace"
        >
          <Helmet>
              <meta charSet="utf-8" />
              <title>Global Fleet Marketplace</title>
              <meta name="description" content="Global Fleet Marketplace. Global Fleet Marketplace is the RFI tool and search engine to identify any product, service or vendor across the globe in the automotive, fleet and mobility ecosystem." />
              <meta name="image" content="https://marketplace.globalfleet.com/static/screenshot.jpg" />
              <meta name="og:image" content="https://marketplace.globalfleet.com/static/screenshot.jpg" />
          </Helmet>
        <Hero />
        <SearchByCategory />
        <div ref={this.myRef1} id="search-by-goal">
          <SearchByGoal />
        </div>
        <BuyerGuide />
        <AboutSlideSection />
        <FeedbackSection />
        <Box style={{backgroundColor: '#f9fbff'}} pb={8}>
          <AreYouVendor />
        </Box>
        <BePartOfCommunity />
      </Page>
    );
  }
}
export default withRouter(HomeView);