import React, { useEffect, useCallback } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Hidden,
  IconButton,
  Link,
  SvgIcon,
  Badge,
  withStyles,
  makeStyles
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import axios from 'axios';
import { BackendURL } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    transition: 'all 0.5s ease'
  },
  toolbar: {
    height: 64,
    maxWidth: '1280px',
    width: '100%',
    margin: '0 auto'
  },
  logo: {
    marginRight: theme.spacing(2),
    maxWidth: '200px'
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#000',
    '& + &': {
      marginLeft: theme.spacing(5)
    }
  },
  linkLight: {
    fontWeight: theme.typography.fontWeightMedium,
    color: '#fff',
    '& + &': {
      marginLeft: theme.spacing(5)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  outlinedButton:{
    [theme.breakpoints.up('md')]:{
      marginRight: '15px'
    }
  },
  itemsContainer:{
    [theme.breakpoints.up('md')]:{
      marginRight: '55px'
    }
  }
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -14,
    top: 13,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '0 4px',
  },
}))(Badge);

const TopBar = ({ className, onMobileNavOpen, status, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const [ reqNum, setReqNum ] = React.useState('0');
  const { isAuthenticated, user, logout } = useAuth();

  const items = [
    { title: 'Marketplace', link: '/marketplace' },
    { title: 'All Categories', link: '/all-categories' },
    { title: 'About Us', link: '/about-us' },
    { title: 'Buyer Guides', link: '/buyer-guides' },
  ];

  const getDataEvery3 = useCallback(async () => {
    try{
      if(user){
        if(user.Type !== "Vendor"){
          return false;
        }
      }
      let res = await axios.get(BackendURL+'/requests/');
      let count = 0;
      if(res.data.length){
        res.data.map((req) => {
            if(req.status !== 'Closed'){
              count = count+1;
            }
          return false;
        });
      }
      setReqNum(count+'');
    }catch(err){
        console.error(err);
    }
  }, [user]);

  const getData = useCallback(async () => {
    try{
      if(user){
        if(user.Type !== "Vendor"){
          return false;
        }
      }else{
        return false;
      }
      let res = await axios.get(BackendURL+'/requests/');
      let count = 0;
      if(res.data.length){
        res.data.map((req) => {
            if(req.status !== 'Closed'){
              count = count+1;
            }
          return false;
        });
      }
      setReqNum(count+'');
      setTimeout(() => {
        getDataEvery3();
      }, 2*60*1000);
    }catch(err){
        console.error(err);
    }
  }, [user, getDataEvery3]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo className={classes.logo} color={className === 'dark'? 'light' : 'dark'} />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon style={className === 'dark'? { color: '#fff' } : null } />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown={true}>
          <Box className={classes.itemsContainer}>
          {
            items.map((item) => {
              if(history.location.pathname === item.link){
                return (
                  <Link
                      key={item.title}
                      className={className === 'dark' ? classes.linkLight :classes.link}
                      onClick={ () => { window.location.reload(); } }
                      style={{ cursor: 'pointer', color:'#49a3da' }}
                      underline="none"
                      variant="body2"
                    >
                    {item.title}
                  </Link>   
                );
              }
              return (
                <Link
                  key={item.title}
                  className={className === 'dark' ? classes.linkLight :classes.link}
                  style={{color: history.location.pathname === item.link? '#49a3da' : null}}
                  component={RouterLink}
                  to={item.link}
                  underline="none"
                  variant="body2"
                >
                  {item.title}
                </Link>    
              );
            })
          }
          </Box>
          {
            isAuthenticated?
            <>
              <Button
                color="primary"
                component={RouterLink}
                to="/app/account"
                variant="outlined"
                size="medium"
                style={user.Type === "Vendor"?{ paddingRight: '30px' }: null}
                className={classes.outlinedButton}
              >
                  {
                    user.Type === "Vendor"?
                      <StyledBadge badgeContent={reqNum} title="Total number of pending requests" color="primary">
                        Account
                      </StyledBadge>
                    : 
                    'Account'
                }
              </Button>
              <Button
                color="primary"
                variant="contained"
                size="medium"
                onClick={() => { logout(); }}
              >
                Logout
              </Button>
              </>
            :
            <>
            <Button
              color="primary"
              component={RouterLink}
              to="/new-vendor"
              variant="outlined"
              size="medium"
              className={classes.outlinedButton}
            >
              Are you a vendor?
            </Button>
            <Button
              color="primary"
              component={RouterLink}
              to="/login"
              variant="contained"
              size="medium"
              className={classes.outlinedButton}
            >
              Sign in
            </Button>
            <Button
              color="primary"
              component={RouterLink}
              to="/signup/regular"
              variant="contained"
              size="medium"
            >
              Sign up
            </Button>
            </>
          }
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
