import React , { useEffect, useCallback, useState }from 'react';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay, Pagination } from 'swiper';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f9fbff',
    paddingTop: '50px',
    paddingBottom: '125px',
    // [theme.breakpoints.up('md')]: {
    //     paddingLeft: () => { 
    //         let container = window.document.getElementsByClassName('MuiGrid-container')[0];
    //         if(container){
    //             let padding = (window.innerWidth - container.clientWidth) / 2;
    //             return padding;
    //         }
    //     }
    // },
    [theme.breakpoints.down('md')]: {
      paddingTop: '0px',
      paddingBottom: '50px'
    }
  },
  swiper:{
    [theme.breakpoints.up('md')]:{
      paddingLeft: '10px',
      marginLeft: '-10px',
      paddingBottom: '66px',
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: '40px',
    },
  },
  swipeSlide:{
    backgroundColor: '#fff',
    borderRadius: '15px',
    boxShadow: '0 10px 10px 0 rgba(152, 149, 149, 0.16)',
    // height: 'auto',
  },
  link:{
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '500'
  },
  arrow:{
    marginLeft: '24px'
  },
  readMore:{
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '700',
    fontSize: '15px',
    '&:hover':{
      textDecoration: 'underline'
    }
  },
  post:{
    height: '100%',
    position: 'relative',
  },
  content:{
        [theme.breakpoints.up('md')]:{
            padding: '40px 25px 40px 25px',
        },
        [theme.breakpoints.down('md')]:{
            padding: '25px 15px 25px 15px',
        }
  },
  title:{
    [theme.breakpoints.up('md')]:{
      fontSize: '20px',
      lineHeight: '28px',
    },
    [theme.breakpoints.down('md')]:{
      fontSize: '16px',
      lineHeight: '22px',
    },
    paddingBottom: '25px'
  },
  caption:{
      fontStlye: 'italic',
      color: '#49a3da',
      [theme.breakpoints.up('md')]:{
        fontSize: '12px',
        lineHeight: '16px'
      },
      [theme.breakpoints.down('md')]:{
        fontSize: '10px',
        lineHeight: '14px'
      }

  },
  '.swiper':{
      maxWidth: '610px'
  },
  info:{
    backgroundColor: 'rgba(73, 163, 218, 0.17)',
    borderRadius: '15px',
    padding: '15px 24px 15px 20px'
  },
  avatar:{
    backgroundColor: '#fff',
    borderRadius: '50%',
    width: '70px',
    height: '70px',
    objectFit: 'cover',
  },
  infoContainer:{
    paddingLeft: '10px',
    [theme.breakpoints.down('md')]:{
      paddingLeft: '17px',
    }
  }
}));

const FeedbackSection = ({ className, ...rest }) => {
    const classes = useStyles();
    const [ posts, setPosts ] = useState(null);
    SwiperCore.use([Autoplay, Pagination]);

    const getPosts = useCallback(async () => {
        try{
            let data = [
              { id: 5, title: '”Marketplace is the perfect platform to connect customers, service providers and suppliers. You can find the right contact person quickly and intuitively and it definitely helps to grow your fleet network and business. Well done and much appreciated.”', avatar: '/static/feedback/Sabrina-Eickelkamp.jpg', name: 'Sabrina Eickelkamp', caption: 'Senior ManagerInternational Corporate, Direct & Guard Sales Mercedes-Benz Passenger Cars'  },
              { id: 6, title: '”Marketplace is a great new tool for international fleet managers to search and compare vendors that provide fleet related services of all kinds. It can be especially useful when you get new responsibilities over regions or countries where you are not familiar with local supply of fleet services.”', avatar: '/static/feedback/Tamas-Littner.jpg', name: 'Tamas Littner', caption: 'Global Fleet Operation Manager GE Vernova'  },
              { id: 7, title: '”Being part of one of the largest fleet directories in Europe is vital to our future success. It is a way we can link new customers who are looking for mobility support. We are part of how the fleet market operates and our partnership with Fleet Europe is vital to help share our innovative solutions, expertise and collaboration to those who need it.”', avatar: '/static/feedback/Adrian-Bewley.jpeg', name: 'Adrian Bewley', caption: 'Assistant Vice President of European Business Rental'  },
              { id: 8, title: '”For us, the Global Fleet Marketplace tool is a “one-stop-shop” for Fleet Managers looking to reach out to various providers in the Mobility sector to satisfy one or other need. Needless to say, from the side of the supplier community, it provides the possibility to increase company visibility and reach into the Fleet Manager / Procurement Community with a view to supporting partners in their long-term business development. In short, it’s a win-win environment(!)”', avatar: '/static/feedback/Mark-Howlett.jpg', name: 'Mark Howlett', caption: 'Sales Head of Sales Strategy & Corporate Sales Kia '  },
              { id: 9, title: '”Global Fleet Marketplace is a great place to find the best suppliers for our RFI/RFP, and a tool to network within the industry leaders. Easy to use, another good solution for Fleet Managers.”', avatar: '/static/feedback/Teresa-Casulleras.jpg', name: 'Teresa Casulleras', caption: 'Manager Sourcing Travel, Fleet & Events EMEA Group Procurement Merck'},
              { id: 10, title: '”We all are bombarded in our daily business by people reaching out, new services on offer , tools that we must desire and its all moving so fast. But there’s no better way to check out the facts and the people, than a quick visit to Marketplace. And you know everyone’s doing the same – you need to be listed to be in the game!”', avatar: '/static/feedback/Kay-Saunders.jpg', name: 'Kay Saunders', caption: 'Head of Global Fleet Operations Polestar'},
              { id: 0, title: '”94% of the decision makers part of the focus group recommend their colleagues and peers to select the right vendor on Marketplace!”', avatar: '/static/feedback/caroline.png', name: 'Caroline Thonnon', caption: 'CEO, Nexus Communication'  },
              { id: 1, title: '“Innovative and easy to use ! Marketplace is very useful to find out about all the services out there and being able to filter them. I plan to use it regularly!”', avatar: '/static/feedback/Monroy.png', name: 'Rodrigo Monroy', caption: 'Sourcing Manager – US Corporate Spend, Novartis'  },
              { id: 2, title: '“Once more Nexus Communication is proving to be an organization that understands the needs of our industry and drives solutions and spaces to collaborate for our joint business benefits”', avatar: '/static/feedback/Sebastian.png', name: 'Sebastian Fuchs', caption: 'Managing Director Manheim and RMS Automotive Continental Europe, Cox Automotive Europe'  },
              { id: 3, title: '”Another great initiative by Nexus Communication, that will help the customers to find a comprehensive and legitimate fleet and remarketing providers overview to help them to take the best decision.”', avatar: '/static/feedback/Marcel.png', name: 'Marcel de Rycker', caption: 'Chief Commercial Officer, Proovstation'  },
              { id: 4, title: '”Great job! Replacing some of the input from a standard RFI, will not only allow for further efficiencies for our customers and prospects but also for us, suppliers. In our world where flexibility and mobility are keywords, Marketplace is a great tool to discover new vendors and new products and services. The marketplace can support our business development and allow customers to contact us directory. Well done.”', avatar: '/static/feedback/vinzenz.jpeg', name: 'Vinzenz Planfz', caption: 'President Corporate Sales, Sixt'  },
            ];             
            setPosts(data);
        }catch(err){
            console.error(err);
        }
    }, [setPosts]);

    useEffect(() => {
        getPosts();
    }, [getPosts]);

    if(!posts){
        return null;
    }
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg" className={classes.container}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="h2"
                color="textPrimary"
              >
                What do users say?
              </Typography>
              <Box mt={2}>
                <Typography variant="h4" color="textPrimary" style={{fontWeight: '400'}}>    
                  Global Fleet Marketplace is trusted by great teams and businesses
                </Typography>
              </Box>
                <Box mt={5}>
                    <Swiper
                        spaceBetween={20}
                        modules={[Navigation, Pagination]}
                        slidesPerView={2}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false
                        }}
                        pagination={{ clickable: true }}
                        className={classes.swiper}
                        // onSlideChange={(swiper) => console.log(swiper)}
                        breakpoints={{
                          320: {
                            slidesPerView: 1,
                            spaceBetweenSlides: 10
                          },
                          // when window width is <= 480px
                          480: {
                            slidesPerView: 2,
                            spaceBetweenSlides: 20
                          },
                          // when window width is <= 640px
                          640: {
                            slidesPerView: 2,
                            spaceBetweenSlides: 30
                          }
                        }}
                    >
                    {
                      posts.map((post, i) => {
                          return (
                              <SwiperSlide key={post.id} className={classes.swipeSlide} style={{maxWidth: '560px', marginLeft: i === 0? '0px': null}}>
                                  <Box px={2}>
                                    <Box className={classes.post}>
                                        <Box className={classes.content}>
                                            <Typography color="textPrimary" variant="h3" className={classes.title}>
                                                {post.title}
                                            </Typography>
                                            <Grid container className={classes.info}>
                                              <Grid item md={2} xs={3}>
                                                <img className={classes.avatar} alt="Avatar" src={post.avatar} />
                                              </Grid>
                                              <Grid item container xs={9} style={{alignItems: 'center'}}>
                                                <Box className={classes.infoContainer}>
                                                  <Typography variant="h5" color="textPrimary" style={{fontWeight: '400'}}>
                                                      {post.name}
                                                  </Typography>
                                                  <Typography variant="body1" className={classes.caption} style={{fontWeight: '400'}}>
                                                      {post.caption}
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                  </Box>
                              </SwiperSlide>
                          );
                      })
                    }
                    </Swiper>
                </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default FeedbackSection;