import React , { useEffect, useCallback, useState }from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  makeStyles,
  Hidden
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import axios from 'axios';
import { BackendURL, WPBackendURL } from 'src/constants';
import { Markup } from 'interweave';
// Slider
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay, Pagination } from 'swiper';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f9fbff',
    paddingTop: '31px',
    paddingBottom: '48px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '30px',
      paddingBottom: '30px'
    }
  },
  link:{
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '500'
  },
  arrow:{
    marginLeft: '24px'
  },
  readMore:{
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '700',
    fontSize: '15px',
    '&:hover':{
      textDecoration: 'underline'
    }
  },
  post:{
    backgroundColor: '#fff',
    borderRadius: '15px',
    boxShadow: '0 10px 10px 0 rgba(152, 149, 149, 0.16)',
    height: '100%',
    position: 'relative',
  },
  content:{
    paddingLeft: '28px',
    paddingRight: '31px',
        [theme.breakpoints.up('md')]:{
          paddingBottom: '60px'
        },
        [theme.breakpoints.down('md')]:{
          paddingBottom: '36px'
        }
  },
  readmoreContainer:{
        [theme.breakpoints.up('md')]:{
            position: 'absolute',
            bottom: '29px',
            maxWidth: '85%'
        }
  },
  image:{
      width: '100%',
      maxWidth: '100%',
      borderTopRightRadius: '20px',
      borderTopLeftRadius: '20px',
  },
  title:{
      marginTop: '25px',
      paddingBottom: '17px',
  },
  caption:{
      paddingBottom: '20px'
  },
  GridColumn:{
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw'
    }
  },
  postGrid:{
    [theme.breakpoints.down('md')]: {
      paddingRight: '10px !important'
    },
    textDecoration: 'none'
  },
  postGridSlide:{
    [theme.breakpoints.down('md')]: {
      paddingRight: '10px !important'
    },
    height: 'auto'
  },
  swiper:{
    paddingBottom: '60px'
  }
}));

const BuyerGuide = ({ className, ...rest }) => {
    const classes = useStyles();
    const [ posts, setPosts ] = useState(null);
    const [ banner, setBanner ] = useState();
    const [ bannerLink, setBannerLink ] = useState();
    SwiperCore.use([Autoplay, Pagination]);

    const getPosts = useCallback(async () => {
        try{
            let res = await axios.get(WPBackendURL+'/posts?_embed');
            let data = [];
            res.data.map(async (post, i) => {
              // if(i > 2){
              //   return null;
              // }
              if(post.categories.includes(280)){
                  post.excerpt.rendered = post.excerpt.rendered.replace('<p>', '');
                  post.excerpt.rendered = post.excerpt.rendered.replace('</p>', '');
                  if(res.data){
                    data.push(post);
                  }
                  if(post.featured_media){
                    // console.log(post['_embedded']['wp:featuredmedia'][0]['source_url']);
                    post.featured_media = post['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['portfolio-thumb']['source_url'];
                  }
              }
              return false;
            });
            setPosts(data);
            // Get Banner
            let res1 = await axios.get(BackendURL+'/dashboard/banner/fetch');
            if(!res1.data.image){
              setBanner('/media/banners/Marketplace-Banner-default_1EHwXwg.png');
            }else{  
              setBanner(res1.data.image);
            }
            res1 = await axios.get(BackendURL+'/dashboard/banner/link/fetch');
            if(res1.data.banner_link){
              setBannerLink(res1.data.banner_link);
            }else{
              setBannerLink('https://conference.globalfleet.com/');
            }
        }catch(err){
            console.error(err);
        }
    }, [setPosts]);

    useEffect(() => {
        getPosts();
    }, [getPosts]);

    if(!posts || !banner){
        return null;
    }
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="h2"
                color="textPrimary"
              >
                Buyer guides
              </Typography>
              <Box mt={2}>
                <Typography variant="h5" color="textPrimary" style={{fontSize: '18px', lineHeight: '22px'}}>    
                    Find the latest tips and best practices by global mobility and fleet management experts
                </Typography>
              </Box>
              <Box mt={6}>
                {/* Desktop */}
                <Hidden smDown={true}>
                  <Grid container className={classes.GridColumn} spacing={6}>
                    {
                        posts.map((post, i) => {
                            return (
                                <Grid item md={4} xs={12} className={classes.postGrid} component={RouterLink} to={'/buyer-guides/'+post.id} underline="none" key={post.id}>
                                    <Box className={classes.post}>
                                      {
                                        post.featured_media?
                                          <Link
                                            component={RouterLink}
                                            to={'/buyer-guides/'+post.id}
                                            underline="none"
                                          >
                                              <img src={post.featured_media} className={classes.image} alt="guide" />
                                          </Link>
                                        :<Box pt={2}/>
                                      }
                                        <Box className={classes.content}>
                                            <Typography color="textPrimary" variant="h4" className={classes.title}>
                                                <Markup content={post.title.rendered} />
                                                {/* {post.title.rendered} */}
                                            </Typography>
                                            <Typography color="textPrimary" variant="body1" className={classes.caption}>
                                                {post.excerpt.rendered}
                                            </Typography>
                                            <Grid container className={classes.readmoreContainer}>
                                                <Grid item container xs={6} style={{alignItems: 'center'}}>
                                                    <Link
                                                        className={classes.readMore}
                                                        component={RouterLink}
                                                        to={'/buyer-guides/'+post.id}
                                                        underline="none"
                                                        variant="body1"
                                                    >
                                                        Read more
                                                    </Link>  
                                                </Grid>
                                                <Grid item container xs={6} style={{alignItems: 'center', justifyContent: 'flex-end'}}>
                                                    <Link
                                                        className={classes.link}
                                                        component={RouterLink}
                                                        to={'/buyer-guides/'+post.id}
                                                        underline="none"
                                                        variant="body1"
                                                    >
                                                        <ChevronRightIcon style={{color: '#000'}} /> 
                                                    </Link>  
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                            );
                        })
                    }
                  </Grid>
                </Hidden>
                {/* Mobile */}
                <Hidden smUp={true}>
                <Swiper
                    spaceBetween={20}
                    modules={[Navigation, Pagination]}
                    slidesPerView={2}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false
                    }}
                    pagination={{ clickable: true }}
                    className={classes.swiper}
                    // onSlideChange={(swiper) => console.log(swiper)}
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                        spaceBetweenSlides: 10
                      },
                      // when window width is <= 480px
                      480: {
                        slidesPerView: 2,
                        spaceBetweenSlides: 20
                      },
                      // when window width is <= 640px
                      640: {
                        slidesPerView: 2,
                        spaceBetweenSlides: 30
                      }
                    }}
                  >
                    {
                        posts.map((post, i) => {
                            return (
                              <SwiperSlide key={post.id} className={classes.postGridSlide}>
                                    <Box className={classes.post}>
                                      {
                                        post.featured_media?
                                          <Link
                                            component={RouterLink}
                                            to={'/buyer-guides/'+post.id}
                                            underline="none"
                                          >
                                              <img src={post.featured_media} className={classes.image} alt="guide" />
                                          </Link>
                                        :<Box pt={2}/>
                                      }
                                        <Box className={classes.content}>
                                            <Typography color="textPrimary" variant="h4" className={classes.title}>
                                                <Markup content={post.title.rendered} />
                                                {/* {post.title.rendered} */}
                                            </Typography>
                                            <Typography color="textPrimary" variant="body1" className={classes.caption}>
                                                {post.excerpt.rendered}
                                            </Typography>
                                            <Grid container className={classes.readmoreContainer}>
                                                <Grid item container xs={6} style={{alignItems: 'center'}}>
                                                    <Link
                                                        className={classes.readMore}
                                                        component={RouterLink}
                                                        to={'/buyer-guides/'+post.id}
                                                        underline="none"
                                                        variant="body1"
                                                    >
                                                        Read more
                                                    </Link>  
                                                </Grid>
                                                <Grid item container xs={6} style={{alignItems: 'center', justifyContent: 'flex-end'}}>
                                                    <Link
                                                        className={classes.link}
                                                        component={RouterLink}
                                                        to={'/buyer-guides/'+post.id}
                                                        underline="none"
                                                        variant="body1"
                                                    >
                                                        <ChevronRightIcon style={{color: '#000'}} /> 
                                                    </Link>  
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                            );
                        })
                    }
                    </Swiper>
                </Hidden>
              </Box>
                {/* Buttons */}
                <Box mt={7} mb={10}>
                  <Grid container className={classes.GridColumn}>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center">
                        <Link
                          className={classes.link}
                          component={RouterLink}
                          to={'/buyer-guides'}
                          underline="none"
                          variant="body1"
                        >
                            Discover more guides
                          <ChevronRightIcon className={classes.arrow} /> 
                        </Link>  
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {/* Banner */}
                <Box textAlign="center" component="a" target="_blank" href={bannerLink}>
                  <img style={{maxWidth: '100%'}} src={BackendURL+banner} alt="Banner" />
                </Box>
            </Box>
          </Grid>
        </Grid>
        
      </Container>
    </div>
  );
};

export default BuyerGuide;