import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Container,
    Link,
    Button,
    Typography,
    withStyles
} from '@material-ui/core';
// import MailIcon from '@material-ui/icons/Mail';
// import CallIcon from '@material-ui/icons/Call';

const styles = (theme) => ({
    root:{
        backgroundColor: '#2F3441',
        [theme.breakpoints.up('md')]:{
            paddingTop: '82px',
            paddingBottom: '82px',
        },
        [theme.breakpoints.down('md')]:{
            paddingTop: '42px',
            paddingBottom: '42px',
        },
    },
    linkIcon:{
        color: '#fff',
        display: 'flex',
        marginBottom: '15px',
        alignItems: 'center',
        transition: 'all 0.3s ease',
        '&:hover':{
            opacity: 0.7
        }
    },
    link:{
        color: '#fff',
        display: 'block',
        marginBottom: '15px',
        fontWeight: '500',
        transition: 'all 0.3s ease',
        '&:hover':{
            opacity: 0.7,
            cursor: 'pointer'
        }
    },
    social:{
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 0.3s ease',
        '&:hover':{
            opacity: 0.6
        }
    },
    mainGrid:{
        [theme.breakpoints.up('md')]:{
            display: 'flex',
        }
    },
    heading:{
        '&:after':{
            content: '""',
            width: '100%',
            borderTop: '1px solid rgba(255,255,255,0.5)',
            display: 'block',
            marginTop: '5px'
        }
    },
    outlinedButton:{
        borderRadius: '40px'
    },
    box1:{
        [theme.breakpoints.up('md')]:{
            width: '36%',
            paddingRight: '5%'
        } 
    },
    box2:{
        [theme.breakpoints.down('md')]:{
            marginTop: '30px'
        },
        [theme.breakpoints.up('md')]:{
            width: '27%',
            paddingRight: '25px'
        } 
    },
    box3:{
        [theme.breakpoints.down('md')]:{
            marginTop: '30px'
        },
        [theme.breakpoints.up('md')]:{
            width: '17%',
            paddingRight: '25px'
        } 
    },
    box4:{
        [theme.breakpoints.down('md')]:{
            marginTop: '30px'
        },
        [theme.breakpoints.up('md')]:{
            width: '23%',
            paddingRight: '25px'
        } 
    }
});

class Footer extends React.Component {

    render(){
        const { classes } = this.props;

        const list1 = [
            { id: 1, title: 'About Us', link: '/about-us' },
            { id: 0, title: 'Features and Benefits', link: '/about-us' },
            { id: 2, external: true, title: 'Search by Goal', link: '/#search-by-goal' },
            { id: 3, title: 'All Categories', link: '/all-categories' },
            { id: 4, title: 'FAQs', link: '/faqs' },
        ];

        const list2 = [
            { id: 0, title: 'Why join', link: '/about-us' },
            { id: 1, title: 'Get Listed', link: '/new-vendor' },
            { id: 2, external: true, hightlited: true, title: 'Vendor Login', link: '/login' },
            { id: 3, external: true, title: 'Admin Login', link: 'https://dashboard.globalfleet.com/login' },
        ];

        const list3 = [
            { id: 0, external: true, title: 'Privacy Policy', link: 'https://www.globalfleet.com/en/privacy-policy' },
            { id: 2, external: true, title: 'Terms of Use', link: 'https://www.globalfleet.com/en/general-conditions-sale' },
        ];

        return(
            <div className={classes.root}>
                <Container maxWidth="lg">
                    <Box className={classes.mainGrid}>
                        <Box className={classes.box1}>
                            <img src="/static/footer-logo.png" style={{maxWidth: '100%'}} alt="light logo"/>
                            <Typography variant="body1" style={{color: '#fff', marginTop: '25px'}}>
                                Global Fleet Marketplace is your RFI tool and search engine to identify any product, service or vendor across the globe in the automotive, fleet and mobility ecosystem.
                            </Typography>
                        </Box>
                        <Box className={classes.box2}>
                            <Typography variant="h4" className={classes.heading} style={{color: '#fff', fontWeight: '400', marginBottom: '30px'}}>
                                Global Fleet Marketplace
                            </Typography>
                            <Box>
                                {
                                    list1.map((item) => {
                                        if(item.external){
                                            return (
                                                <Link
                                                    key={item.title}
                                                    className={classes.link}
                                                    component="a"
                                                    target={item.link !== '/#search-by-goal'? '_blank': null}
                                                    href={item.link}
                                                    underline="none"
                                                    variant="body2"
                                                >
                                                    {item.title}
                                                </Link>   
                                            );
                                        }
                                        return (
                                            <Link
                                                key={item.title}
                                                className={classes.link}
                                                component={RouterLink}
                                                to={item.link}
                                                underline="none"
                                                variant="body2"
                                            >
                                                {item.title}
                                            </Link>   
                                        );
                                    })
                                }
                            </Box>
                        </Box>
                        <Box className={classes.box3}>
                            <Typography variant="h4" className={classes.heading} style={{color: '#fff', fontWeight: '400', marginBottom: '30px'}}>
                                For Vendors
                            </Typography>
                            <Box>
                                {
                                    list2.map((item) => {
                                        if(item.external){
                                            return (
                                                <Link
                                                    key={item.title}
                                                    className={classes.link}
                                                    component="a"
                                                    href={item.link}
                                                    target="_blank"
                                                    underline="none"
                                                    variant="body2"
                                                    style={
                                                        item.hightlited? { color: '#1dbf73', fontWeight: 'bold' }
                                                            : item.title === "Admin Login" ?
                                                                { color: '#52B3EE', fontWeight: 'bold' }
                                                            :null
                                                        }
                                                >
                                                    {item.title}
                                                </Link>   
                                            );
                                        }
                                        return (
                                            <Link
                                                key={item.title}
                                                className={classes.link}
                                                component={RouterLink}
                                                to={item.link}
                                                underline="none"
                                                variant="body2"
                                                style={item.hightlited? { color: '#1dbf73', fontWeight: 'bold' }: null}
                                            >
                                                {item.title}
                                            </Link>   
                                        );
                                    })
                                }
                            </Box>
                        </Box>
                        <Box className={classes.box3}>
                            <Typography variant="h4" className={classes.heading} style={{color: '#fff', fontWeight: '400', marginBottom: '30px'}}>
                                Information
                            </Typography>
                            <Box>
                                {
                                    list3.map((item) => {
                                        if(item.external){
                                            return (
                                                <Link
                                                    key={item.title}
                                                    className={classes.link}
                                                    component="a"
                                                    target="_blank"
                                                    href={item.link}
                                                    underline="none"
                                                    variant="body2"
                                                    style={item.hightlited? { color: '#1dbf73', fontWeight: 'bold' }: null}
                                                >
                                                    {item.title}
                                                </Link>   
                                            );
                                        }
                                        return (
                                            <Link
                                                key={item.title}
                                                className={classes.link}
                                                component={RouterLink}
                                                to={item.link}
                                                underline="none"
                                                variant="body2"
                                                style={item.hightlited? { color: '#1dbf73', fontWeight: 'bold' }: null}
                                            >
                                                {item.title}
                                            </Link>   
                                        );
                                    })
                                }
                            </Box>
                        </Box>
                        <Box className={classes.box4}>
                            <Typography variant="h4" className={classes.heading} style={{color: '#fff', fontWeight: '400', marginBottom: '30px'}}>
                                Contact Us
                            </Typography>
                            <Box mt={3}>
                                <Typography vaiant="body1" style={{marginRight: '13px', color: '#fff'}}>
                                    Rue de Lantin 149<br/>
                                    4000 Liège <br/>
                                    Belgium<br/>
                                    <Link href="tel:+3243878794">
                                    {/* <CallIcon style={{marginRight: '13px', color: '#fff'}}/> */}
                                    Phone. +32 4 387 87 94
                                    </Link><br/>
                                    Fax. +32 4 387 90 63<br/>
                                </Typography>
                                {/* <Link href="mailto:email@nexusmarket.com" className={classes.linkIcon}>
                                    <MailIcon style={{marginRight: '13px', color: '#fff'}}/>
                                    email@nexusmarket.com
                                </Link><br/>
                                <Link href="tel:+3243878794" className={classes.linkIcon}>
                                    {/* <CallIcon style={{marginRight: '13px', color: '#fff'}}/> */}
                                    {/* +32 4 387 87 94 
                                </Link> */}
                            </Box>
                            <Box mt={4}>
                                <Button
                                    color="primary"
                                    target="_blank"
                                    component="a"
                                    href="mailto:elambert@nexuscommunication.be"
                                    variant="outlined"
                                    size="large"
                                    className={classes.outlinedButton}
                                >
                                    Contact us
                                </Button>
                            </Box>
                            <Box mt={3}>
                                <Typography variant="h4" className={classes.heading} style={{color: '#fff', fontWeight: '400', marginBottom: '20px'}}>
                                    Follow Us
                                </Typography>
                                <Box display="flex">
                                    <a style={{marginLeft: '10px'}} href="https://www.linkedin.com/groups/4755735/" title="Linkedin" className={classes.social}>
                                        <img src="/static/linkedin.png" style={{maxWidth: '18px'}} alt="I"/>
                                    </a>
                                    <a style={{marginLeft: '10px'}} href="https://www.facebook.com/globalfleet.be/" title="Facebook" className={classes.social}>
                                        <img src="/static/facebook.svg" style={{maxWidth: '100%'}} alt="F"/>
                                    </a>
                                    <a style={{marginLeft: '10px'}} href="https://twitter.com/GlobalFleet" title="Twitter" className={classes.social}>
                                        <img src="/static/twitter.png" style={{maxWidth: '18px'}} alt="I"/>
                                    </a>
                                    <a style={{marginLeft: '10px'}} href="https://www.youtube.com/user/NexusCommunication" title="Youtube" className={classes.social}>
                                        <img src="/static/you.png" style={{maxWidth: '18px'}} alt="I"/>
                                    </a>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </div>
        );
    }
}
export default withStyles(styles)(Footer);