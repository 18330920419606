import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '.dark':{
      backgroundColor: '#2F3441 !important'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    '.MuiButton-containedPrimary > span':{
      color: '#fff'
    },
    '.MuiButton-root':{
      borderRadius: '7px'
    },
    '.MuiButton-root > span':{
      textTransform: 'none'
    },
    'h1, h2, h3, h4, h5, h6, span, p, button, label, a, select, input, li, td, th':{
      fontFamily: 'Inter !important'
    },
    '.searchComponent .MuiInput-underline:before':{
      borderBottom: 'none !important'
    },
    '.searchComponent .MuiAutocomplete-popupIndicator':{
      display: 'none !important'
    },
    // Locations in company profile
    '.tabsContainer span.MuiTabs-indicator':{
      display: 'none',
    },
    // Company profile slider
    '.keyContractsSlider .swiper-button-next, .keyContractsSlider .swiper-button-prev':{
          backgroundColor: '#fff',
          width: '41px',
          height: '41px',
          boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.16)',
          borderRadius: '50%'
    },
    '.keyContractsSlider .swiper-button-next:after, .keyContractsSlider .swiper-button-prev:after':{
      fontSize: '20px !important'
    },
    '.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled,':{
      opacity: '0.7'
    }
  }
}));

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
