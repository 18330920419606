import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent'
  },
  container: {
    backgroundColor: '#1dbf73',
    borderRadius: '33px',
    [theme.breakpoints.up('md')]: {
        padding: '7px 93px 0px 74px'
    },
    [theme.breakpoints.down('md')]: {
        padding: '50px 30px 0px 30px'
    }
  },
  button:{
    backgroundColor: '#fff',
    maxWidth: '330px',
    [theme.breakpoints.down('md')]:{
        fontSize: '13px'
    }
  },
  img:{
      [theme.breakpoints.up('md')]: {
        maxWidth: '260px',
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '90%',
        marginTop: '50px' 
    }
  },
  mediumSize:{
    color: '#fff',
    [theme.breakpoints.up('md')]: {
      fontSize: '18px', 
      lineHeight: '22px', 
    }
  }
}));

const AreYouVendor = ({ className, ...rest }) => {
    const classes = useStyles();

    return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={0}
          className={classes.container}
        >
            <Grid
                item
                md={8}
                xs={12}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    height="100%"
                >
                <Typography
                    variant="h2"
                    color="textPrimary"
                    style={{color: "#fff"}}
                >
                    Are you a vendor?
                </Typography>
                <Box mt={3} mb={4}>
                    <Typography variant="h5" color="textPrimary" className={classes.mediumSize}>    
                      Be part of Global Fleet Marketplace and reach out to the world’s largest fleet and mobility community.
                    </Typography>
                </Box>
                <Button variant="contained" className={classes.button} component={RouterLink} to="/new-vendor" size="large">
                    Create your company profile today
                </Button>
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box display="flex" justifyContent="center">
                    <img src="/static/girl-image@2x.png" alt="Girl" className={classes.img} />
                </Box>
            </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AreYouVendor;