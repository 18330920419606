import React from 'react';
import { 
    Box,
    Grid,
    Typography,
    withStyles
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const styles = (theme) => ({
    root:{
        padding: '11px 14px 11px 26px',
        [theme.breakpoints.up('md')]:{
            marginRight: '10%'
        },
        borderRadius: '35px',
        border: 'solid 1px rgba(28, 28, 28, 0.08)',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.06)',
    },
    input:{
        borderRadius: '35px',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        border: 'solid 0px transparent',
        minHeight: '33px',
        paddingLeft: '10px',
        width: '100%',
        fontSize: '16px',
    },
    suggestionBox:{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    chip:{
        width: '100%',
        maxWidth: '180px',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        margin: '3px 5px',
        borderRadius: '16px',
        [theme.breakpoints.down('md')]:{
            margin: '5px',            
        },
    },
});

class HeroLoading extends React.Component {

    render(){

        const { classes } = this.props;

        return (
            <Grid container className="searchComponent">
                <Grid item md={9} xs={12}>
                    <Box className={classes.root} id="root-hero-search" mt={2}>
                        <Box>
                            <Grid container>
                                <Grid item xs={12} id="hero-input-container">
                                    <Skeleton variant="rect"  height={58} className={classes.input} />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className={classes.suggestionBox} mt={3}>
                            <Typography color="textPrimary" style={{color: '#fff', fontSize: '14px', marginRight: '15px'}}>
                                Suggestions:
                            </Typography>
                            <Skeleton variant="rect" height={32} className={classes.chip} />
                            <Skeleton variant="rect" height={32} className={classes.chip} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(HeroLoading);