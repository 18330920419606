import React, {
  useState,
  useEffect
} from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
// import Cookies from 'js-cookie';
import {
  Box,
  Button,
  TextField,
  FormHelperText,
  InputLabel,
  Portal,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import FeedbackIcon from '@material-ui/icons/Feedback';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: '0 0 5px 0 rgba(152, 149, 149, 0.16)',
    maxWidth: 600,
    position: 'fixed',
    bottom: 0,
    right: 0,
    borderRadius: '15px',
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    zIndex: 2000
  },
  feedback:{
    position: 'fixed',
    zIndex: 1000,
    bottom: '0px',
    right: '100px',
    backgroundColor: theme.palette.primary.main,
    padding: '5px 10px 5px 10px',
    cursor: 'pointer'
  },
  action: {
    // backgroundColor: theme.palette.common.white,
    // color: theme.palette.common.black
  },
  item:{
    borderRadius: '50%',
    border: 'solid 1px #49a3da',
    height: '40px',
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '15px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover':{
      backgroundColor: '#49a3da',
      color: '#fff'
    }
  },
  selectedItem:{
    borderRadius: '50%',
    border: 'solid 1px #49a3da',
    backgroundColor: '#49a3da',
    color: '#fff',
    height: '40px',
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '15px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  },
  label:{
    fontSize: '14px',
    marginTop: '10px'
  },
  feedbackItem:{
    position: 'relative',
    '&:hover':{
      '&:after':{
        display: 'block',
        textAlign: 'center',
        color: '#fff',
        backgroundColor: theme.palette.primary.main, 
        fontSize: '9px',
        borderRadius: '2px',
        position: 'absolute',
        bottom: '-14px',
        padding: '0px',
        fontFamily: 'Inter',
        width: '57px'
      },
      '&:nth-child(1):after':{
        content: '"Very difficult"',
      },
      '&:nth-child(2):after':{
        content: '"difficult"',
      },
      '&:nth-child(3):after':{
        content: '"Fair"',
      },
      '&:nth-child(4):after':{
        content: '"Easy"',
      },
      '&:nth-child(5):after':{
        content: '"Very easy"',
      }
    }
  },
  ratting:{
    marginLeft: '10px',
    cursor: 'pointer'
  }
}));

const FeedBackPopup = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [visiable, setVisiable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stars] = useState([1, 2, 3, 4, 5]);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // const feedback = Cookies.get('feedback');
      setTimeout(() => {
        setVisiable(true);
      }, 60000);
  }, []);

  if(visiable === false && open === false){
    return null;
  }

  if(visiable && open === false){
    return (
      <div className={classes.feedback} onClick={ () => { setOpen(true); }}>
        <Typography variant="h6" style={{ color: '#fff' }}>
          <FeedbackIcon style={{ fontSize: '20px', position: 'relative', top: '5px', marginRight: '5px' }} color="inherit" />
          Tell us about your experience?
        </Typography>
      </div>
    );
  }

  return (
    <Portal>
      <div className={classes.root}>
        <Typography
          variant="h4"
          color="inherit"
          align="center"
          style={{ marginBottom: '7px' }}
        >
          We value your feedback
        </Typography>
        <Typography
          variant="body2"
          align="center"
          color="inherit"
        >
          Would you please take a moment to share your feedback?
        </Typography>
        <Formik
      initialValues={{
        ratting: 5,
        easy: 5,
        email: '',
        feedback: '',
      }}
      validationSchema={Yup.object().shape({
        ratting: Yup.string().required('Ratting is required'),
        easy: Yup.string().required('Ratting is required'),
        email: Yup.string().email('Must be a vaild email').required('Email is required'),
        feedback: Yup.string().min(20).max(500).required('Feedback is required'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
            setLoading(true);
            let data = {
              service_id: 'service_bbg0jek',
              template_id: 'template_45urmbc',
              user_id: 'user_oFWwkVgqkaMu66lRTkoom',
              template_params: {
                  ratting: values.ratting,
                  easy: values.easy,
                  email: values.email,
                  feedback: values.feedback,
              }
            }
            let res  = await axios.post('https://api.emailjs.com/api/v1.0/email/send', data);
            if(res.status === 200 && res.data === "OK"){
              handleClose();
              enqueueSnackbar('Thank you for the Feedback', { variant: 'success' });
              setStatus({ success: true });
              setSubmitting(false);
            }
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoading(false);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
        >
          <InputLabel className={classes.label}>
            How would you rate your experience?
          </InputLabel>
          <Box display="flex" alignItems="center" flexWrap="wrap" mt={2} mb={2} className={classes.checkboxContainer}>
          {
            stars.map((star, i) => {
              if(values.ratting <= i){
                return (
                  <Box onClick={ () => { setFieldValue('ratting', star); } } className={classes.ratting}>
                    <StarBorderIcon style={{ fontSize: '35px', color: '#49a3da' }} />
                  </Box>
                  );
                }else{
                  return (
                    <Box onClick={ () => { setFieldValue('ratting', star); } } className={classes.ratting}>
                      <StarIcon style={{ fontSize: '35px', color: '#49a3da' }} />
                    </Box>
                  );
              }
            })
          }
          </Box>
          <InputLabel className={classes.label}>
            How easy was it for you to use the marketplace? 
          </InputLabel>
          <Box display="flex" alignItems="center" flexWrap="wrap" mt={2} mb={2} className={classes.checkboxContainer}>
            <Box onClick={ () => { setFieldValue('easy', 1); } } style={{ marginLeft: '0px !important' }} className={values.easy === 1? clsx(classes.selectedItem, classes.feedbackItem) :  clsx(classes.item, classes.feedbackItem) }>
              <span>1</span>
            </Box>
            <Box onClick={ () => { setFieldValue('easy', 2); } } className={values.easy === 2? clsx(classes.selectedItem, classes.feedbackItem) :  clsx(classes.item, classes.feedbackItem) }>
              <span>2</span>
            </Box>
            <Box onClick={ () => { setFieldValue('easy', 3); } } className={values.easy === 3? clsx(classes.selectedItem, classes.feedbackItem) :  clsx(classes.item, classes.feedbackItem) }>
              <span>3</span>
            </Box>
            <Box onClick={ () => { setFieldValue('easy', 4); } } className={values.easy === 4? clsx(classes.selectedItem, classes.feedbackItem) :  clsx(classes.item, classes.feedbackItem) }>
              <span>4</span>
            </Box>
            <Box onClick={ () => { setFieldValue('easy', 5); } } className={values.easy === 5? clsx(classes.selectedItem, classes.feedbackItem) :  clsx(classes.item, classes.feedbackItem) }>
              <span>5</span>
            </Box>
          </Box>
          {errors.easy && (
            <Box mt={3}>
              <FormHelperText error>
                {errors.easy}
              </FormHelperText>
            </Box>
          )}
          <TextField
            error={Boolean(touched.feedback && errors.feedback)}
            fullWidth
            multiline
            style={{ minWidth: '300px' }}
            helperText={touched.feedback && errors.feedback}
            label="Do you have any additional comments?"
            margin="normal"
            name="feedback"
            onBlur={handleBlur}
            onChange={handleChange}
            type="feedback"
            value={values.feedback}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
            <Box
              mt={2}
              display="flex"
              justifyContent="flex-start"
              flexDirection="row-reverse"
            >
              <Button
                disabled={loading}
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                className={classes.action}
              >
                Submit
              </Button>
              <Button
                disabled={loading}
                onClick={handleClose}
                variant="outlined"
                color="primary"
                className={classes.action}
                style={{ marginRight: '20px' }}
              >
                Close
              </Button>
            </Box>
            </form>
          )}
        </Formik>
      </div>
    </Portal>
  );
};

export default FeedBackPopup;
