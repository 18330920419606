/* eslint-disable no-use-before-define */
import React, { useEffect, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Button,
  Badge,
  List,
  ListSubheader,
  withStyles,
  makeStyles
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import useAuth from 'src/hooks/useAuth';
import axios from 'axios';
import { BackendURL } from 'src/constants';

const sections = [
  {
    items: [
      {
        title: 'Marketplace',
        href: '/marketplace'
      },
      {
        title: 'All Categories',
        href: '/all-categories'
      },
      {
        title: 'About Us',
        href: '/about-us'
      },
      {
        title: 'Buyer Guides',
        href: '/buyer-guides'
      }
    ]
  },
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  buttons:{
    marginBottom: '20px'
  }
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -14,
    top: 13,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '0 4px',
  },
}))(Badge);

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const [ reqNum, setReqNum ] = React.useState('0');
  const location = useLocation();
  const { isAuthenticated, logout } = useAuth();

  const getDataEvery3 = async () => {
    try{
      let res = await axios.get(BackendURL+'/requests/');
      let count = 0;
      if(res.data.length){
        res.data.map((req) => {
            if(req.status !== 'Closed'){
              count = count+1;
            }
          return false;
        });
      }
      setReqNum(count+'');
    }catch(err){
        console.error(err);
    }
  };

  const getData = useCallback(async () => {
    try{
      let res = await axios.get(BackendURL+'/requests/');
      let count = 0;
      if(res.data.length){
        res.data.map((req) => {
            if(req.status !== 'Closed'){
              count = count+1;
            }
            return false;
        });
      }
      setReqNum(count+'');
      setTimeout(() => {
        getDataEvery3();
      }, 2*60*1000);
    }catch(err){
        console.error(err);
    }
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo color="dark" style={{ maxWidth: '100%' }}/>
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />
        <Box p={2}>
          {sections.map((section, i) => (
            <List
              key={section.subheader+i}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
          {
            isAuthenticated?
              <Box p={2}>
                <Button
                  color="primary"
                  component={RouterLink}
                  to="/app/account"
                  variant="outlined"
                  size="medium"
                  className={classes.outlinedButton}
                  style={{ paddingRight: '30px' }}
                >
                  <StyledBadge badgeContent={reqNum} title="Total number of pending requests" color="primary">
                    Account
                  </StyledBadge>
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="medium"
                  onClick={() => { logout(); }}
                  style={{ marginLeft: '15px' }}
                >
                  Logout
                </Button>
              </Box>
            :
              <Box p={2}>
                <Button
                  color="primary"
                  component={RouterLink}
                  to="/new-vendor"
                  variant="outlined"
                  size="medium"
                  className={classes.buttons}
                >
                  Are you a vendor?
                </Button>
                <Button
                  color="primary"
                  component={RouterLink}
                  to="/login"
                  variant="contained"
                  size="medium"
                  className={classes.outlinedButton}
                >
                  Sign in
                </Button>
                <Button
                  color="primary"
                  component={RouterLink}
                  to="/signup/regular"
                  variant="contained"
                  size="medium"
                  style={{ marginLeft: '15px' }}
                >
                  Sign up
                </Button>
              </Box>
          }
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
