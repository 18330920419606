import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Hidden, Dialog, makeStyles } from '@material-ui/core';
import TopBar from './TopBar';
import NavBar from './NavBar';
import Footer from './Footer';
import SignupDialog from './SignupDialog';
import { useHistory } from 'react-router-dom';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f9fbff',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  backToTop:{
    position: 'fixed',
    right: '20px',
    bottom: '30px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [color, setColor] = useState('dark');
  const [backToTop, setBackToTop] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const { isAuthenticated } = useAuth();

  const scrollTop = () => {
    let el = window.document.getElementById('main-Content');
    el.scrollTo(0, 0);
  }

  useEffect(() => {
    let el = window.document.getElementById('main-Content');
    // console.log(el);
    if(el){
      el.addEventListener('scroll', function(e){
        if(e.target.scrollTop > 100){
          setColor('light');
          setBackToTop(true);
        }else{
          setColor('dark');
          setBackToTop(false);
        }
      });
    }
    let timer = null;
    if(!isAuthenticated){
      timer = setTimeout(() => {
        setOpen(true);
      }, 25*1000);
    }
    history.listen((location) => {
      if(!location.hash){
        setTimeout(() => {
          el.scroll({
            top: 0,
            behavior: 'smooth'
          });
        }, 100);
      }
    });    
    return () => clearTimeout(timer);
  }, [isAuthenticated, history]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} className={color} />
      <Hidden lgUp={true}>
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
      </Hidden>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content} id="main-Content">
            {children}
            <Footer />
            <Dialog
              open={isOpen}
              onClose={() => { setOpen(false); }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            > 
              <SignupDialog close={() => { setOpen(false); }} />
            </Dialog>
            {
              backToTop?
                <div className={classes.backToTop} onClick={ () => { scrollTop(); } }>
                  <KeyboardArrowUpIcon style={{ color: '#fff' }} />
                </div>
              :null
            }
          </div>
        </div>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
