import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import MainLayout from 'src/layouts/MainLayout';
// import WithoutHeaderFooter from 'src/layouts/WithoutHeaderFooter';
import HomeView from 'src/views/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forget-password',
    component: lazy(() => import('src/views/auth/ForgetPassowrd'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/create-password/:token',
    component: lazy(() => import('src/views/auth/CreatePassword'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/app/account/:id?',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/company/edit/:id',
        component: lazy(() => import('src/views/CompanyEdit'))
      },
      // {
      //   exact: true,
      //   path: '/app/company/edit/:id',
      //   component: lazy(() => import('src/views/CompanyEdit'))
      // },
      {
        exact: true,
        path: '/app/company/new',
        component: lazy(() => import('src/views/CompanyNew'))
      },
      {
        exact: true,
        path: '/app/packages',
        component: lazy(() => import('src/views/NewVendorPricingOnly'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  // {
  //   path: '/signup',
  //   layout: WithoutHeaderFooter,
  //   routes: [
  //     {
  //       exact: true,
  //       path: '/signup',
  //       component: lazy(() => import('src/views/Signup'))
  //     },
  //     {
  //       exact: true,
  //       path: '/signup/regular',
  //       component: lazy(() => import('src/views/SignupRegular'))
  //     },
  //   ]
  // },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        exact: true,
        path: '/marketplace',
        component: lazy(() => import('src/views/Marketplace/MainView'))
      },
      {
        exact: true,
        path: '/marketplace/filters',
        component: lazy(() => import('src/views/Marketplace/MainView'))
      },
      {
        exact: true,
        path: '/marketplace/homepage-search',
        component: lazy(() => import('src/views/Marketplace/MainView'))
      },
      {
        exact: true,
        path: '/marketplace/suggestions',
        component: lazy(() => import('src/views/Marketplace/MainView'))
      },
      {
        exact: true,
        path: '/marketplace/search-by-category/:categotyId',
        component: lazy(() => import('src/views/Marketplace/MainView'))
      },
      {
        exact: true,
        path: '/marketplace/search-by-goal',
        component: lazy(() => import('src/views/Marketplace/MainView'))
      },
      {
        exact: true,
        path: '/marketplace/search-by-products',
        component: lazy(() => import('src/views/Marketplace/MainView'))
      },
      {
        exact: true,
        path: '/marketplace/company/:id',
        component: lazy(() => import('src/views/Marketplace/CompanyView'))
      },
      {
        exact: true,
        path: '/marketplace/filters/company/:id',
        component: lazy(() => import('src/views/Marketplace/CompanyView'))
      },
      {
        exact: true,
        path: '/marketplace/comparison/:id',
        component: lazy(() => import('src/views/Marketplace/Comparison'))
      },
      {
        exact: true,
        path: '/create/request/:id',
        component: lazy(() => import('src/views/SignupQuiz'))
      },
      {
        exact: true,
        path: '/signup/regular',
        component: lazy(() => import('src/views/SignupRegular'))
      },
      {
        exact: true,
        path: '/all-categories',
        component: lazy(() => import('src/views/Categories'))
      },
      {
        exact: true,
        path: '/search-by-goal/:id?',
        component: lazy(() => import('src/views/Quiz'))
      },
      {
        exact: true,
        path: '/new-vendor',
        component: lazy(() => import('src/views/newVendor'))
      },
      {
        exact: true,
        path: '/buyer-guides/:id',
        component: lazy(() => import('src/views/SinglePost'))
      },
      {
        exact: true,
        path: '/buyer-guides',
        component: lazy(() => import('src/views/BuyerGuides'))
      },
      {
        exact: true,
        path: '/about-us',
        component: lazy(() => import('src/views/AboutUs'))
      },
      {
        exact: true,
        path: '/cookies',
        component: lazy(() => import('src/views/Cookies'))
      },
      {
        exact: true,
        path: '/terms-conditions',
        component: lazy(() => import('src/views/TermsConditions'))
      },
      {
        exact: true,
        path: '/copyrights',
        component: lazy(() => import('src/views/Copyrights'))
      },
      {
        exact: true,
        path: '/faqs',
        component: lazy(() => import('src/views/FAQs'))
      },
      {
        exact: true,
        path: '/error',
        component: lazy(() => import('src/views/errors/Error'))
      },
      {
        exact: true,
        path: '/soon',
        component: lazy(() => import('src/views/errors/CommingSoon'))
      },
      {
        exact: true,
        path: '/soon/account/:id?',
        component: lazy(() => import('src/views/errors/CommingSoon'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
];

export default routes;
