import React , { useEffect, useCallback, useState }from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import {
  Box,
  Container,
  Grid,
  Radio,
  Typography,
  Button,
  makeStyles
} from '@material-ui/core';
import { TrackGoogleAnalyticsEvent } from '../Marketplace/GoogleAnalyticsFunctionality';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    paddingTop: '31px',
    paddingBottom: '48px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '30px',
      paddingBottom: '30px'
    }
  },
  checkBoxContainer:{
    paddingTop: '5px',
    paddingBottom: '5px',
    '&:hover':{
        cursor: 'pointer'
    }
  },
  link:{
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '500'
  },
  mediumSize:{
    [theme.breakpoints.up('md')]: {
      fontSize: '18px', 
      lineHeight: '22px', 
    }
  },
  GridColumn:{
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw'
    }
  }
}));

const SearchByGoal = ({ className, ...rest }) => {
    const classes = useStyles();
    const [checked, setChecked] = useState([]);
    const [ searchOptions, setSearchOptions ] = useState(null);
    const history = useHistory();

    const handleChange = (event, optionId) => {
        setChecked(optionId);
    };

    const getOptions = useCallback(async () => {
        try{
            let data = [
              { id: 1, title: 'I want to increase safety' },
              { id: 2, title: 'I want to implement connectivity' },
              { id: 3, title: 'I want to electrify my fleet' },
              { id: 4, title: 'I want to find a full service lease partner' },
              { id: 5, title: 'I want to integrate corporate mobility' },
            ];             
            setSearchOptions(data);
        }catch(err){
            console.error(err);
        }
    }, [setSearchOptions]);

    useEffect(() => {
        getOptions();
    }, [getOptions]);

    if(!searchOptions){
        return null;
    }
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="h2"
                color="textPrimary"
              >
                Search by goal
              </Typography>
              <Box mt={2}>
                <Typography
                  variant="h4"
                  color="textPrimary"
                  style={{fontWeight: '500'}}
                >
                  The Global Fleet Marketplace can help you reach your objectives
                </Typography>
                <Box mt={2}></Box>
                <Typography variant="h5" color="textPrimary" style={{fontSize: '18px', lineHeight: '22px', fontWeight: '700'}}>    
                  Question 1 out of 4: Please select your main goal and we will show you how to do it and who to partner with. 
                </Typography>
              </Box>
              <Box mt={3}>
                <Grid container spacing={6} className={classes.GridColumn}>
                    <Grid item md={6} xs={12}>
                        {
                          searchOptions.map((option, i) => {
                            return (
                                  <Box display="flex" key={option.title} onClick={ () => { handleChange(null, option.id) } } className={classes.checkBoxContainer} justifyContent="flex-start" alignItems="center">
                                      <Radio
                                        key={option.title} 
                                        checked={option.id === checked}
                                        onChange={(e) => { handleChange(e, option.id); }}
                                        value={option.id === checked? false : true} 
                                        name={'search'+i}
                                        color="primary"
                                        inputProps={{ 'aria-label': option.title }}
                                      />
                                      <Typography variant="h5" color="textPrimary" className={classes.mediumSize}>    
                                          {option.title}
                                      </Typography>
                                  </Box>
                            );
                          })
                        }
                    </Grid>
                    <Grid item container md={6} xs={12} style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                      <Box display="flex" alignItems="flex-end" >
                          <Box style={{flexGrow: 1}} />
                          <Button size="large" disabled={checked.length === 0} variant="contained" color="primary" 
                            onClick={ () => { 
                              history.push('/search-by-goal/'+checked);
                              TrackGoogleAnalyticsEvent(
                                'search_by_goal',
                                'search_by_goal',
                                "Start search by goal"
                            );
                            } }>
                            Start
                          </Button>
                        </Box>
                    </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SearchByGoal;