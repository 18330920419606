import React, { useState } from 'react';
import clsx from 'clsx';
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { TrackGoogleAnalyticsEvent } from 'src/views/Marketplace/GoogleAnalyticsFunctionality';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('md')]:{
            width: '800px', 
            maxWidth: '100%', 
        }
    },
    contentSection: {
        backgroundColor: theme.palette.background.default
    },
}));

const DeleteForm = ({className, close, ...rest}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [ isSubmitting, setSubmitting ] = useState(false);

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Grid
              container
              spacing={4}
            >
              <Grid item xs={12}>
                <Typography variant='h2' color='textPrimary' align='center'>
                    Join us now
                </Typography>
                <Typography variant='body1' color='textPrimary' align='center'>
                    Find your global, regional and local fleet and mobility suppliers and service providers
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <Box p={2} mb={1} display="flex" justifyContent="center">
            <Button
              color="primary"
              disabled={isSubmitting}
              onClick={close}
              variant="contained"
              component={RouterLink}
              to="/signup/regular"
            >
              User Sign Up 
            </Button>
            <Button
              color="secondary"
              disabled={isSubmitting}
              onClick={close}
              variant="contained"
              component={RouterLink}
              to="/new-vendor"
              style={{ margin: '0px 15px' }}
            >
              Vendor Sign Up
            </Button>
            <Button
              color="primary"
              disabled={isSubmitting}
              onClick={() => {
                close();
                TrackGoogleAnalyticsEvent(
                  'popup_will_do_later_button',
                  'popup_will_do_later_button',
                  "Clicked on I will do later"
                );
              }}
              variant="outlined"
            >
              I will do later
            </Button>
          </Box>
        </Card>
    );
}

export default DeleteForm;