import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import HeroSeach from './HeroSeach';
import Logo from 'src/components/Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: '#49a3da',
    backgroundImage: 'url("/static/bg.jpeg")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    paddingTop: '73px',
    paddingBottom: '73px',
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  },
  logo:{
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      top: '16px'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '15px'
    }
  }
}));

const Hero = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="h1"
                style={{color: '#fff'}}
              >
                Welcome to
                <Logo color="white" className={classes.logo} style={{maxHeight: '50px'}} />
              </Typography>
              <Box mt={2}>
                <Typography
                  variant="h3"
                  style={{color: '#fff'}}
                >
                  Your new RFI tool in Fleet & Mobility
                </Typography>
              </Box>
              <Box mt={1}>
                <HeroSeach />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
