import React from 'react';
import { 
    Box,
    Grid,
    Chip,
    TextField,
    Select,
    MenuItem,
    // ListSubheader,
    FormControl,
    FormHelperText,
    withStyles,
    Typography
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import * as Yup from 'yup';
import { Formik } from 'formik';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import { BackendURL } from 'src/constants';
import './style.css';
import HeroLoading from './HeroLoading';
import { TrackGoogleAnalyticsEvent } from '../Marketplace/GoogleAnalyticsFunctionality';
// import countries from './countries';

const styles = (theme) => ({
    root:{
        backgroundColor: '#fff',
        padding: '11px 14px 11px 26px',
        [theme.breakpoints.up('md')]:{
            marginRight: '10%'
        },
        borderRadius: '35px',
        border: 'solid 1px rgba(28, 28, 28, 0.08)',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.06)',
    },
    input:{
        backgroundColor: 'transparent',
        border: 'solid 0px transparent',
        minHeight: '33px',
        paddingLeft: '10px',
        width: '100%',
        fontSize: '16px',
        '&:focus':{
            outline: 'none'
        },
        '&::placeholder':{
            color: '#000',
            opacity: 0.37,
            fontSize: '16px',
            lineHeight: '25px'
        }
    },
    select:{
        backgroundColor: 'transparent',
        border: 'solid 0px transparent',
        fontSize: '16px',
        minHeight: '33px',
        '&:focus':{
            outline: 'none'
        },
    },
    chips:{
        backgroundColor: '#fff',
        margin: '3px 5px',
        [theme.breakpoints.down('md')]:{
            margin: '5px',            
        },
    },
    suggestionBox:{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    }
});

class HeroSeach extends React.Component {
    state = {
        selectOptions:[
            { name: 'Geographical Scope', value: null },
        ],
        suggestions: [
            { id: 0, region: 'Europe', category: 24, subcategory: 'Electrification', product: 'Charging solutions in Europe', 
                products: ['EV Charging Station', 'EV Charging Platforms', 'EV Charging Infrastructure'] 
            },
            { id: 1, country: 'Germany', category: 23, product: 'Mobility as a service in Germany' },
            { id: 2, country: 'United States of America', category: 25, subcategory: 'Hardware', product: 'Telematics in the US' },
            { id: 3, country: 'France', category: 23, product: 'Mobility solutions in France' },
        ],
        regions: [],
        dropdown: null
    };

    async getData(){
        try{
            let res = await axios.get(BackendURL+'/hero/');
            // console.log(res.data);
            let arr = [];
            for(let x = 0; x < res.data.categories.length; x++){
                for(let i = 0; i < res.data.categories.length; i++){
                    if(!arr.includes(res.data.categories[x])){
                        arr.push(res.data.categories[x]);
                    }
                }
            }
            for(let x = 0; x < res.data.categories.length; x++){
                for(let i = 0; i < res.data.categories[x].subcategory.length; i++){
                    if(!arr.includes(res.data.categories[x].subcategory[i])){
                        arr.push(res.data.categories[x].subcategory[i]);
                    }
                }
            }
            // console.log(arr);
            // console.log(res.data.categories);
            this.setState({ unprocessedList: res.data.categories });
            // console.log(arr);
            let x =  0;
            let reformatedCompanies = arr.reduce((akku, row) => {
                const companyRows = [];
                // row.companies.map((name) => {
                    // console.log(name);
                    if(x === 0){
                        if(row.subcategory){
                            arr.map((row) => {
                                if(row.subcategory){
                                    if(!companyRows.includes({type: 'Categories',name: row.name})){
                                        companyRows.push({
                                            type: 'Categories',
                                            name: row.name,
                                            catID: row.id,
                                            rule: 'category',
                                            keywords: row.name
                                        });
                                    }
                                }
                                return false;
                            });
                        }
                    }
                    if(row.companies){
                        row.companies.map((subItem) => {
                            companyRows.push({
                                type: row.name,
                                name: subItem.name,
                                rule: 'company',
                                slug: subItem.slug,
                                keywords: subItem.region[0]? subItem.region[0]: subItem.name
                            });
                            return false;
                            // });
                            //   return false;
                        });
                    }
                    if(row.subcategory){
                        row.subcategory.map((subcategory) => {
                            companyRows.push({
                                type: row.name,
                                parentCat: row.id,
                                rule: 'subcategory',
                                name: subcategory.name,
                                keywords: subcategory.name
                            });
                            return false;
                            // });
                            //   return false;
                        });
                    }
                    x++;
                akku = akku.concat(companyRows);
                return akku;
            }, []);
            this.setState({ dropdown: reformatedCompanies });
            // Regions
            let res1 = await axios.get(BackendURL+'/hero/regions/');
            let regionsProcessesArr = [];
            res1.data.map((item) => {
                if(item.type !== 'region'){
                    regionsProcessesArr.push(item);
                }
                return false;
            });
            regionsProcessesArr = regionsProcessesArr.sort(function(a, b) {
                return a.name.localeCompare(b.name);
            });
            // console.log(regionsProcessesArr);
            this.setState({ selectOptions: regionsProcessesArr });
            let regions = [];
            res1.data.map((item) => {
                if(item.type === 'region'){
                    if(!regions.includes(item)){
                        regions.push(item);
                    }
                }
                return false;
            });
            this.setState({ regions: regions });
        }catch(err){
            console.error(err);
        }
    }

    handleSuggestion(suggestion){
        if(!suggestion){
            return null;
        }
        let category = suggestion.category;

        let region = null;
        let country = null;
        let data = null;
        if(suggestion.region){
            region =  suggestion.region; 
            data = JSON.stringify({ category: category, region: region, subcategories: suggestion.subcategory, products: suggestion.products });
        }
        if(suggestion.country){
            country =  suggestion.country; 
            data = JSON.stringify({ category: category, country: country, subcategories: suggestion.subcategory, products: suggestion.products });
        }
        localStorage.setItem('suggestions', data);
        this.props.history.push('/marketplace/suggestions');
    }

    componentDidMount(){
        this.getData();    
    }    

    render(){
        if(!this.state.dropdown){
            return <HeroLoading />;
        }
        const { classes } = this.props;

        const filterOptions = createFilterOptions({
            matchFrom: 'any',
            stringify: (option) => { return option.name; },
            // stringify: (option) => { return option.keywords; },
        });

        return(
            <Grid container className="searchComponent">
                <Grid item md={9} xs={12}>
                    <Box className={classes.root} id="root-hero-search" mt={2}>
                    <Formik
                        initialValues={{
                            search: '',
                            searchObject: {},
                            geographical_scope: 'Geographical scope',
                        }}
                        validationSchema={Yup.object().shape({
                            search: Yup.string().max(255).required('Search is required'),
                        })}
                        onSubmit={async (values, {
                            setErrors,
                            setStatus,
                            setSubmitting
                        }) => {
                            try {
                                TrackGoogleAnalyticsEvent(
                                    'use_hero_search',
                                    'use_hero_search',
                                    "Hero Search submit"
                                );
                                let selectedCategory = null;
                                let selectedSubcategory = null;

                                if(values.searchObject.rule === 'category'){
                                    selectedCategory = values.searchObject.catID;  
                                }
                                if(values.searchObject.rule === 'subcategory'){
                                    selectedCategory = values.searchObject.parentCat;  
                                    selectedSubcategory = values.searchObject.name;
                                }
                                if(values.searchObject.rule === 'company'){ 
                                    this.props.history.push('/marketplace/company/'+values.searchObject.slug);
                                    return false;
                                }

                                let geographical_scope = null;
                                if(values.geographical_scope === "Geographical scope"){
                                    // geographical_scope = { name: 'Select All', type: 'country' }
                                    // alert('Please choose Geographical scope');
                                    geographical_scope = { name: 'All', type: 'region' };
                                }else{
                                    let arr = this.state.regions.concat(this.state.selectOptions);
                                    arr.map((option) => {
                                        if(values.geographical_scope === option.name){
                                            if(option.type){
                                                geographical_scope = { name: option.name, type: option.type };
                                            }else{
                                                geographical_scope = { name: option.name, type: 'country' };
                                            }
                                        }
                                        return false;
                                    });
                                }
                                localStorage.removeItem('HeroSearchCategoryId');
                                localStorage.removeItem('HeroSearchSubCategories');
                                localStorage.removeItem('HeroRegion');
                                // console.log('category', selectedCategory);
                                // console.log('subcategory', selectedSubcategory);
                                // console.log('region', geographical_scope);
                                // return false;
                                localStorage.setItem('HeroSearchCategoryId', selectedCategory);
                                localStorage.setItem('HeroSearchSubCategories', selectedSubcategory);
                                localStorage.setItem('HeroRegion', JSON.stringify(geographical_scope));
                                this.props.history.push('/marketplace/homepage-search');
                            } catch (err) {
                                console.error(err);
                                setStatus({ success: false });
                                setErrors({ submit: 'Error '+ err.response.status });
                                setSubmitting(false);
                            }
                        }}
                        >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            setFieldValue,
                            handleSubmit,
                            isSubmitting,
                            values
                        }) => (
                            <Grid container>
                                <Grid item md={7} xs={12} id="hero-input-container">
                                    {/* <input type="text" value={values.search} className={classes.input} name="search" placeholder="What are you looking for?" 
                                        onChange={ (e) => { 
                                            setFieldValue('search', e.target.value); 
                                        }} 
                                    /> */}
                                    <Autocomplete
                                        filterOptions={filterOptions}
                                        options={this.state.dropdown}
                                        // getOptionLabel={(option) => { return option.title || '' } }
                                        groupBy={(option) => option.type}
                                        getOptionLabel={(option) => option.name || ''}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {/* <Markup content={option.name + `<span style={{fontSize: '5px'}}>${option.type}<span>`} /> */}
                                                {option.name}
                                            </React.Fragment>                                  
                                        )}
                                        // closeIcon= { () => { return; } }
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="What are you looking for?" value={values.search} label="" name="search" />
                                        )}
                                        onChange={ (e, obj) => { 
                                            // console.log(obj); 
                                            setFieldValue('searchObject', obj); 
                                            setFieldValue('search', obj.name); } }
                                        // onInputChange={(e, value) => {  }}
                                        // getOptionSelected={(option) => option.year === 1994}
                                        // getOptionSelected={(e, val) => { 
                                            // console.log(val); setFieldValue('searchObject', val); 
                                            // return val;
                                        // }}
                                    />
                                </Grid>
                                <Grid item xs="auto" style={{marginLeft: 'auto'}}>
                                    {/* <select className={classes.select} id="hero-select-input" onChange={ (e) => {
                                        setFieldValue('geographical_scope', e.target.value);
                                        this.updateDropdown(values.search, e.target.value);
                                    } }>
                                        {
                                            this.state.selectOptions.map((country, i) => {
                                                return (
                                                    <option key={i} selected={ values.geographical_scope === country.name? true: false  } value={country.name}>{country.name}</option>
                                                );
                                            })
                                        }
                                    </select> */}
                                    <FormControl>
                                        <Select 
                                            defaultValue=""
                                            fullWidth={true}
                                            id="hero-select-input"
                                            className={classes.select}
                                            value={values.geographical_scope}
                                            variant="standard"
                                            onChange={ (e) => { 
                                                setFieldValue('geographical_scope', e.target.value);
                                            } }
                                        >
                                        <MenuItem value="Geographical scope">
                                            Geographical scope 
                                        </MenuItem>
                                            <MenuItem disabled value="">
                                                Regions
                                            </MenuItem>
                                            {
                                                this.state.regions.length > 0?
                                                    this.state.regions.map((region, i) => {
                                                        return (
                                                            <MenuItem key={i} value={region.name}>{region.name}</MenuItem>
                                                            );
                                                        })
                                                :null
                                            }
                                            <MenuItem disabled value="">
                                                Countries
                                            </MenuItem>
                                            {
                                                this.state.selectOptions.map((country, i) => {
                                                    return (
                                                        <MenuItem key={i} value={country.name}>{country.name}</MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs="auto" style={{marginLeft: 'auto'}}>
                                    <IconButton onClick={handleSubmit} disabled={isSubmitting} aria-label="Search" style={{padding: '5px', backgroundColor: '#8A2BE2'}}>
                                        <SearchIcon style={{color: '#fff'}}/>
                                    </IconButton>
                                </Grid>
                                {errors.submit && (
                                    <FormHelperText>
                                        {errors.submit}
                                    </FormHelperText>
                                )}
                            </Grid>
                        )}
                        </Formik>
                    </Box>
                    {/* Suggestions */}
                    {
                        this.state.suggestions?
                            <Box className={classes.suggestionBox} mt={3}>
                                <Typography color="textPrimary" style={{color: '#fff', fontSize: '14px', marginRight: '15px'}}>
                                    Suggestions:
                                </Typography>
                                {
                                    this.state.suggestions.map((suggestion) => 
                                        <Chip key={suggestion.product} onClick={ () => { this.handleSuggestion(suggestion); } } className={classes.chips} label={suggestion.product} />
                                    )
                                }
                            </Box>
                        :null
                    }
                </Grid>
            </Grid>
        );
    }
}
export default withRouter(withStyles(styles)(HeroSeach));