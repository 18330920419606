import React , { useEffect, useCallback, useState }from 'react';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f9fbff',
    paddingTop: '71px',
    paddingBottom: '66px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '0px',
      paddingBottom: '40px'
    }
  },
  link:{
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontWeight: '500'
  },
  arrow:{
    [theme.breakpoints.up('md')]:{
      marginLeft: '24px'
    },
    [theme.breakpoints.down('md')]:{
      marginLeft: '5px'
    },
  },
}));

const BePartOfCommunity = ({ className, ...rest }) => {
    const classes = useStyles();
    const [ data, setData ] = useState(null);

    const getData = useCallback(async () => {
        try{
            let res = [
              { id: 0, title: 'Fleet Europe Newsletter', link: 'https://mailchi.mp/405069b7e1e2/clhj40a2md' },
              { id: 1, title: 'Fleet Europe Website', link: 'https://www.fleeteurope.com/en' },
              { id: 2, title: 'Smart Mobility Website', link: 'https://www.fleeteurope.com/en/themes/smart-mobility' },
              { id: 3, title: 'Global Fleet Newsletter', link: 'https://mailchi.mp/f9959ff9c817/wjpm1e9kk2' },
              { id: 4, title: 'Global Fleet Website', link: 'https://www.globalfleet.com/en' },
              { id: 5, title: 'Fleet LatAm Website', link: 'https://www.fleetlatam.com/' },
            ];             
            setData(res);
        }catch(err){
            console.error(err);
        }
    }, [setData]);

    useEffect(() => {
        getData();
    }, [getData]);

    if(!data){
        return null;
    }
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="h2"
                color="textPrimary"
              >
                Be part of our community
              </Typography>
              <Box mt={2}>
                <Typography variant="h5" color="textPrimary" style={{fontSize: '18px', lineHeight: '22px'}}>    
                  Don’t miss any features, analyses, webinars, events, or any other information. Register now for our newsletters or visit our websites!
                </Typography>
              </Box>
              <Box mt={3}>
                <Grid container spacing={1}>
                    {
                        data.map((link) => {
                            return (
                                <Grid key={link.id} item xs={12} md={4}>
                                    <Link
                                        className={classes.link}
                                        component="a"
                                        href={link.link}
                                        target="_blank"
                                        underline="none"
                                        variant="body1"
                                    >
                                        {link.title}
                                        <ChevronRightIcon className={classes.arrow} /> 
                                    </Link>  
                                </Grid>
                            );
                        })
                    }
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default BePartOfCommunity;