import React , { useEffect, useCallback, useState }from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Link,
  Radio,
  Typography,
  Tooltip,
  Button,
  makeStyles
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import axios from 'axios';
import { BackendURL } from 'src/constants';
import { useHistory } from 'react-router-dom';
import { TrackGoogleAnalyticsEvent } from '../Marketplace/GoogleAnalyticsFunctionality';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f9fbff',
    paddingTop: '31px',
    paddingBottom: '31px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '30px',
      paddingBottom: '30px'
    }
  },
  checkBoxContainer:{
    paddingTop: '5px',
    paddingBottom: '5px',
    borderBottom: 'solid 1px rgba(112, 112, 112, 0.18)',
    '&:hover':{
        cursor: 'pointer'
    }
  },
  link:{
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '500'
  },
  arrow:{
    marginLeft: '24px'
  },
  mediumSize:{
    [theme.breakpoints.up('md')]: {
      fontSize: '18px', 
      lineHeight: '22px', 
    }
  },
  GridColumn:{
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw'
    }
  },
  firstCol:{
    [theme.breakpoints.down('md')]: {
      paddingBottom: '0px !important'
    },
  },
  secondCol:{
    [theme.breakpoints.down('md')]: {
      paddingTop: '12px !important'
    },
  }
}));

const SearchByCategory = ({ className, ...rest }) => {
    const classes = useStyles();
    const [ searchOptions, setSearchOptions ] = useState(null);
    const [ selectedValue, setSelectedValue ] = useState(null);
    const history = useHistory();

    const getOptions = useCallback(async () => {
        try{
            // let data = [
            //     { title: 'Financial services, leasing & rental', tool: 'We classify the vendors in this category in hardware, software, platforms and apps. Today, technology is there to support you in any possible way: from asset tracking, routing, usage-based insurance to fuel and mobility management and many others.' },
            //     { title: 'Vehicles', tool: 'In this category, you will find suppliers of cars, vans, trucks and 2-wheelers. These suppliers can be OEMs, distributors or dealer networks.' },
            //     { title: 'Energy solutions and fuel', tool: 'Fueling your fleet has been for a long time a matter of fuel cards. Today, many vendors offer services and products related to electrification, charging solutions, fuel management, carbon footprint, sustainability advice. Discover them in this category.' },
            //     { title: 'Strategic advice and outsourcing', tool: 'In your search for an efficient fleet management, you might be looking for expert advice or outsourcing services. The vendors listed in this category offer services in the field of strategy, sourcing, in-life management, vehicle disposal and logistics.' },
            //     { title: 'Mobility solutions', tool: 'Corporate mobility is one everyone’s mind. But the mobility solutions are not yet well-known. In this category you will discover vendors in micro mobility, mobility solutions (car sharing, bike sharing, bike leasing, shuttle services, ride hailing,…) and Mobility as a Service.' },
            //     { title: 'Fleet management technology', tool: 'Global Fleet collaborates with many B2B media companies around the world and fleet associations. Discover them in this category.' },
            //     { title: 'Technology, telematics & connected services', tool: 'We classify the vendors in this category in hardware, software, platforms and apps. Today, technology is there to support you in any possible way: from asset tracking, routing, usage-based insurance to fuel and mobility management and many others.' },
            //     { title: 'Safety, risk and compliance', tool: 'Safety is a primary element of your fleet management. In this category, you will find vendors who can help you improve safety for your drivers, who can support you in risk management and insurance companies.' },
            //     { title: 'Maintenance, repair and tyres (MRT)', tool: 'Discover the vendors that support you the best way to take care of your vehicle – from regular maintenance and repairs to seasonal tyre changes. ' },
            //     { title: 'Remarketing and used cars', tool: 'In this category you will discover the complete eco-system of car remarketing and the used-car market: Traders, Consultants, End of Lease service providers, data collection & analytics, auctioneers, inspection service providers and Used car sales portals.' },
            // ];        
            let res = await axios.get(BackendURL+'/home/categories/');
            setSearchOptions(res.data);
        }catch(err){
            console.error(err);
        }
    }, [setSearchOptions]);
    useEffect(() => {
        getOptions();
    }, [getOptions]);

    if(!searchOptions){
        return null;
    }
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="h2"
                color="textPrimary"
              >
                Search by Category
              </Typography>
              <Box mt={2}>
                <Typography
                  variant="h4"
                  color="textPrimary"
                  style={{fontWeight: '500'}}
                >
                  Discover local and international vendors based on their product and services
                </Typography>
              </Box>
              <Box mt={3}>
                <Grid container spacing={6} className={classes.GridColumn}>
                    <Grid item md={6} xs={12} className={classes.firstCol}>
                        {
                            searchOptions.map((option, i) => {
                                return (
                                        i < searchOptions.length /2?
                                            <Box display="flex" key={i} onClick={ () => { setSelectedValue(option.id); } } className={classes.checkBoxContainer} justifyContent="flex-start" alignItems="center">
                                                <Radio
                                                    checked={selectedValue === option.id}
                                                    value={i}
                                                    color="primary"
                                                    name="search"
                                                    inputProps={{ 'aria-label': i }}
                                                />
                                                <Typography variant="h5" color="textPrimary" className={classes.mediumSize}>    
                                                    {option.name}
                                                </Typography>
                                                <Box style={{flexGrow: 1}} />
                                                <Tooltip title={option.description?option.description: 'No data'} placement="right">
                                                    <InfoIcon style={{color: '#49a3da'}} />
                                                </Tooltip>
                                            </Box>
                                        : null
                                );
                            })
                        }
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.secondCol}>
                        {
                            searchOptions.map((option, i) => {
                                return (
                                        i >= searchOptions.length /2?
                                            <Box display="flex" key={i} onClick={ () => { setSelectedValue(option.id); } } className={classes.checkBoxContainer} justifyContent="flex-start" alignItems="center">
                                                <Radio
                                                  checked={selectedValue === option.id}
                                                  value={i}
                                                  color="primary"
                                                  name="search"
                                                  inputProps={{ 'aria-label': i }}
                                                />
                                                <Typography variant="h5" color="textPrimary" className={classes.mediumSize}>    
                                                    {option.name}
                                                </Typography>
                                                <Box style={{flexGrow: 1}} />
                                                <Tooltip title={option.description?option.description: 'No data'} placement="right">
                                                    <InfoIcon style={{color: '#49a3da'}} />
                                                </Tooltip>
                                            </Box>
                                        : null
                                );
                            })
                        }
                    </Grid>
                </Grid>
              </Box>
              {/* Buttons */}
              <Box mt={4}>
                  <Grid container>
                    <Grid item md={6} xs={12}>
                      <Box display="flex" alignItems="center">
                        <Link
                          className={classes.link}
                          component={RouterLink}
                          to={'/all-categories'}
                          underline="none"
                          variant="body1"
                        >
                          Show all categories
                          <ChevronRightIcon className={classes.arrow} /> 
                        </Link>  
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>  
                        <Box display="flex" alignItems="center">
                          <Box style={{flexGrow: 1}} />
                          <Button size="large" disabled={selectedValue === null} variant="contained" color="primary" onClick={ () => { 
                                // console.log(selectedValue); 
                                history.push('/marketplace/search-by-category/'+selectedValue);
                                TrackGoogleAnalyticsEvent(
                                  'search_by_category',
                                  'search_by_category',
                                  "Start seatch by category from homepage section"
                                );
                              } }>
                            Show the result
                          </Button>
                        </Box>
                    </Grid>
                  </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SearchByCategory;