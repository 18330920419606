import React from 'react';
import { Helmet } from 'react-helmet';

const GA_MEASUREMENT_ID = 'G-7TFY87BBQ9';

const GoogleAnalytics = () => {
  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
        `}
      </script>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-VW3F11N0E7"/>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-VW3F11N0E7');
        `}
      </script>
    </Helmet>
  );
};

export default GoogleAnalytics;
