export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const BackendURL = "https://apigfmarketplace.online";
// export const BackendURL = "http://209.97.134.198";
// export const WPBackendURL = "https://globalfleetmarketplace.com/sales/wp-json/wp/v2";
export const WPBackendURL = "https://global-fleet.mo-stage.com/wp-json/wp/v2";

export const Config = {
  headers: {
    'responseType': 'json',
    'Content-Type': 'application/json;charset=utf-8',
  }
}