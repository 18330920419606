import React , { useEffect, useCallback, useState }from 'react';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay, Pagination } from 'swiper';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f9fbff',
    paddingTop: '0px',
    paddingBottom: '58px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '0px',
      paddingBottom: '30px'
    }
  },
  swiper:{
    [theme.breakpoints.up('md')]:{
      paddingLeft: '10px',
      marginLeft: '-10px',
      paddingBottom: '66px',
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: '40px',
    },
  },
  swipeSlide:{
    backgroundColor: '#fff',
    borderRadius: '15px',
    boxShadow: '0 10px 10px 0 rgba(152, 149, 149, 0.16)',
  },
  link:{
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '500'
  },
  arrow:{
    marginLeft: '24px'
  },
  readMore:{
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '700',
    fontSize: '15px',
    '&:hover':{
      textDecoration: 'underline'
    }
  },
  post:{
    height: '100%',
    position: 'relative',
  },
  content:{
        [theme.breakpoints.up('md')]:{
            padding: '40px 35px 40px 33px',
        },
        [theme.breakpoints.down('md')]:{
            padding: '25px 35px 40px 35px',
        }
  },
  title:{
      [theme.breakpoints.up('md')]:{
        fontSize: '20px',
        lineHeight: '28px',
      },
      [theme.breakpoints.down('md')]:{
        fontSize: '16px',
        lineHeight: '22px',
      },
  },
  caption:{
      paddingBottom: '42px'
  },
  '.swiper':{
      maxWidth: '500px'
  }
}));

const AboutSlideSection = ({ className, ...rest }) => {
    const classes = useStyles();
    const [ posts, setPosts ] = useState(null);
    SwiperCore.use([Autoplay, Pagination]);

    const getPosts = useCallback(async () => {
        try{
            let data = [
              { id: 0, title: 'Global Fleet Marketplace lists all vendors and suppliers in the fleet & mobility ecosystem. All over the world, with local or international products and services.' },
              { id: 1, title: 'Global Fleet Marketplace is the one-stop show for all your automotive, fleet & mobility needs.' },
              { id: 2, title: 'Global Fleet Marketplace gives fleet & mobility decision-makers the chance to discover, search, compare, save searches, and contact directly suppliers.' },
              { id: 3, title: 'Global Fleet Marketplace is the only tool in the world that helps you optimise your RFI and procurement processes.' },
              { id: 4, title: 'Global Fleet Marketplace is an initiative of Nexus Communication (Belgium), publisher of Global Fleet, Fleet Europe, Fleet LATAM, Fleet APAC and Smart Mobility Management.' },
            ];           
            setPosts(data);
        }catch(err){
            console.error(err);
        }
    }, [setPosts]);

    useEffect(() => {
        getPosts();
    }, [getPosts]);

    if(!posts){
        return null;
    }
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg" className={classes.container}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="h2"
                color="textPrimary"
              >
                About Global Fleet Marketplace
              </Typography>
              <Box mt={2}>
                <Typography variant="h4" color="textPrimary" style={{fontWeight: '400'}}>    
                    All your global, regional and local fleet and mobility suppliers and service providers
                </Typography>
              </Box>
                <Box mt={2}>
                    <Swiper
                        spaceBetween={20}
                        modules={[Navigation, Pagination]}
                        slidesPerView={2}
                        autoplay={{
                          delay: 2500,
                          disableOnInteraction: false
                        }}
                        pagination={{ clickable: true }}
                        className={classes.swiper}
                        // onSlideChange={(swiper) => console.log(swiper)}
                        breakpoints={{
                          320: {
                            slidesPerView: 1,
                            spaceBetweenSlides: 10
                          },
                          // when window width is <= 480px
                          480: {
                            slidesPerView: 2,
                            spaceBetweenSlides: 20
                          },
                          // when window width is <= 640px
                          640: {
                            slidesPerView: 2,
                            spaceBetweenSlides: 30
                          }
                        }}
                    >
                    {
                      posts.map((post, i) => {
                          return (
                              <SwiperSlide key={post.id} className={classes.swipeSlide} style={{maxWidth: '500px', marginLeft: i === 0? '0px': null}}>
                                  <Box px={2}>
                                    <Box className={classes.post}>
                                        <Box className={classes.content}>
                                            <Typography color="textPrimary" variant="h3" className={classes.title}>
                                                {post.title}
                                            </Typography>
                                        </Box>
                                    </Box>
                                  </Box>
                              </SwiperSlide>
                          );
                      })
                    }
                    </Swiper>

                </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AboutSlideSection;